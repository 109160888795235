<template>

  <div>

    <div class="relative content-wrapper w-full bg-gradient-to-b from-blue-25 to-yellow-25 overflow-hidden px-16 py-16">

      <section class="relative z-50 max-w-7xl mx-auto">

        <div class="flex">
          <div class="sm:w-full xl:w-5/12 py-6">
            <div class="space-y-12">
              <h1 class="text-3xl font-extrabold">
                Küldetésünk, hogy megmentsük a fiók mélyére száműzött emlékeidet
              </h1>
              <div class="text-lg font-light">
                Készen állsz a kalandra? Egészen idáig utaztál, így itt az ideje, hogy találkozz a MEMOFOX Videószerkesztő szolgáltatásával.
              </div>
              <div class="space-x-4">
                <router-link class="inline-flex justify-center py-4 px-6 border border-transparent text-lg font-normal rounded-lg text-white bg-indigo-600 hover:bg-indigo-800 focus:outline-none" :to="{ name: 'Order', params: {} }">
                  Szerezz egy videót
                </router-link>
                <router-link class="inline-flex justify-center py-4 px-6 border text-lg font-normal rounded-lg text-white focus:outline-none border-2 border-indigo-600 text-indigo-600 hover:border-indigo-800 hover:text-indigo-800" :to="{ name: 'Order', params: {} }">
                  Ismerd meg
                </router-link>
              </div>
            </div>
          </div>
          <div class="sm:w-full xl:w-7/12 px-12">
            <img class="w-auto h-auto" src="@/assets/hero-image.png" alt="">
          </div>
        </div>

      </section>

      <img class="absolute top-0 left-0 hero-map background" src="@/assets/svg/map-hero.svg" alt="">

    </div>

    <section class="w-full bg-white">
      <div class="max-w-4xl mx-auto px-16 py-16">
        <h2 class="text-center text-3xl font-bold mb-6">Mindenkinek van kamerája</h2>
        <p class="text-center">
          A kérdés az, hogy mit kezdesz azzal a számtalan felvétellel, amelyeket a fontos eseményeiden készítettél? <span class="is-strong">Bízd ránk!</span>
          A feltöltött videóidat egy hangulatos klippé varázsoljuk, amely igazi audiovizuális filmélmény lesz a néző számára.
          Hangulatos zenével, a legjobb pillanatok kiemelésével egy maximálisan testre szabott kisfilmet kapsz, amellyel újra átélheted és megoszthatod barátaiddal az izgalmas kalandodat.
        </p>
      </div>
    </section>

    <section class="w-full bg-blue-25 text-center px-16 py-16">
      <FourStep />
      <router-link class="inline-flex justify-center py-4 px-6 border border-transparent text-lg font-normal rounded-lg text-white bg-indigo-600 hover:bg-indigo-800 focus:outline-none" :to="{ name: 'Order', params: {} }">
        Próbáld ki
      </router-link>
    </section>

    <section class="w-full text-center px-16 py-16">
      <SkillRef />
    </section>

    <section>
      <LatestVideo />
    </section>

  </div>

</template>

<script>
import Nav from './components/nav'
import FourStep from './components/fourStep'
import SkillRef from './components/skillReference'
import Footer from './components/footer'
import LatestVideo from './components/latestVideo'

export default {
  components: {
    Nav, FourStep, SkillRef, Footer, LatestVideo
  },
  data() {
    return {

    }
  },
  created() {

  }
}
</script>

<style lang="scss">

</style>
