<template>

  <div class="max-w-12xl mx-auto relative">

    <div class="absolute w-full h-full max-h-full overflow-hidden">

      <transition name="custom" enter-active-class="animate__animated animate__slideInUp" leave-active-class="animate__animated animate__slideOutDown">
        <div v-if="showModal" class="absolute z-30 bg-black w-full h-full flex items-center justify-center overflow-hidden">
          <div class="absolute z-20 right-0 top-0 p-12">
            <router-link @click="showModal = false" class="text-gray-100" :to="{ name: '', params: {} }">
              <ph-x :size="48" />
            </router-link>
          </div>
          <div class="relative z-10 h-full w-full flex items-center justify-center self-center">
            <VimeoPlayer v-bind:id="644883885" class="w-full h-auto" />
          </div>
          <div class="absolute w-10 h-10 text-blue-100">
            <div style="border-top-color:transparent" class="w-12 h-12 border-8 border-gray-400 border-solid rounded-full animate-spin"></div>
          </div>
        </div>
      </transition>

      <div class="max-w-7xl mx-auto h-full lg:px-16">
        <div class="flex items-center justify-start h-full relative">

          <div class="lg:w-7/12 md:w-6/12 z-20 lg:h-auto md:h-full flex flex-col relative self-center p-16 lg:bg-white md:bg-gray-100 lg:rounded-lg lg:shadow-xl space-y-6">
            <h3 class="lg:text-2xl md:text-xl font-extrabold">
              Nézd meg legújabb videónkat!
            </h3>
            <div class="text-md md:text-sm font-light">
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.
            </div>
            <div class="">
              <router-link @click="showModal = !showModal" class="inline-flex justify-center lg:py-4 lg:px-6 md:py-2 md:px-4 border border-transparent lg:text-md text-sm font-normal rounded-md text-white bg-indigo-600 hover:bg-indigo-800 focus:outline-none" :to="{ name: '', params: {} }">Videó lejátszása</router-link>
            </div>

          </div>

        </div>
      </div>

    </div>

    <div class="grid grid-cols-2 gap-0">
      <div class="col-start-1 col-end-1 bg-gray-200"></div>
      <div class="col-start-2 col-end-2 overflow-hidden">
        <img class="lg:w-full" src="@/assets/image/iceland-cover.png" alt="">
      </div>
    </div>

  </div>
</template>

<script>
import VimeoPlayer from './vimeoPlayer'

export default {
  components: {
    VimeoPlayer
  },
  data() {
    return {
      showModal: false
    }
  }
}
</script>

<style lang="css" scoped>
</style>
