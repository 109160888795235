import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

import firebase from 'firebase/app'
import 'firebase/auth'

const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: 'commerce',
        name: 'secretProducts',
        component: () => import('../views/secret/Products.vue')
      },
      {
        path: 'faq',
        name: 'secretFaq',
        component: () => import('../views/secret/Faq.vue')
      }
    ]
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/order',
    name: 'Order',
    component: () => import('../views/Order.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('../views/Signup.vue')
  },
  {
    path: '/forgott-pass',
    name: 'ForgottPass',
    component: () => import('../views/ForgottPass.vue')
  },
  {
    path: '/success',
    name: 'Success',
    component: () => import('../views/Success.vue')
  },
  {
    path: '/*',
    name: '404',
    component: function () {
      return import('../views/404.vue')
    },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const isAuthenticated = firebase.auth().currentUser

  if (requiresAuth && !isAuthenticated) {
    next('/login')
  } else {
    next()
  }
})

export default router
