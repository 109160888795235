<template>
  <div class="relative max-w-7xl mx-auto mx-auto">
    <h2 class="text-center text-3xl font-bold mb-6">Minden MemoFox videó tartalmazza</h2>
    <div class="max-w-4xl mx-auto px-16 py-1">
      <p class="text-center mb-10 text-gray-600">A felvételeidet dinamikus átmenetekkel, figyelemfelkeltő színkorrekcióval és képstabilizálással szerkesztjük. A profik által szerkesztett emlékedet mindenki szeretni fogja!</p>
    </div>
    <div class="grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-4 text-center">
      <div v-for="(item, i) in skills" :key="i" class="space-y-4 bg-blue-25 p-6 rounded-xl shadow-sm">
        <div class="flex justify-center">
          <component :is="item.image" class="text-indigo-600"/>
        </div>
        <div class="text-lg font-bold">
          <span>{{ item.name }}</span>
        </div>
        <div class="">
          {{ item.desc }}
        </div>
      </div>
    </div>
    <img class="absolute bottom-0 left-500 background map" src="@/assets/svg/map.svg" alt="">
  </div>
</template>

<script>
import {markRaw} from "vue"
import { PhVideoCamera, PhEyedropper, PhVibrate, PhMusicNotes, PhPen, PhSwap, PhHorse, PhHeart, PhCube } from "phosphor-vue";

export default {
  components: {
    PhHorse,
    PhHeart,
    PhCube
  },
  provide: {
   size: 48,
   weight: "duotone",
   mirrored: false
  },
  data () {
    return {
      skills: [
        {
          name: 'Zenék és hangok',
          desc: 'Tökéletesen illesztett zene és finomhangolt effektek. A videódat a 8000+ jogdíjmentes dalunkból választott zene ütemére szerkesztjük. Oszd meg videódat bármely közösségi médiában, szerzői jogi korlátozások nélkül.',
          image: markRaw(PhMusicNotes)
        },
        {
          name: 'Rázkódás javítása',
          desc: 'Minden videóból kihozzuk a lehető legtöbbet. Javítjuk a kéz által okozott képremegést, hiszen senki sem született gimbalnak. Szerkesztőink néha kész csodákat művelnek a felvételekkel.',
          image: markRaw(PhVibrate)
        },
        {
          name: '100%-ban a Tied',
          desc: 'Az elkészült videóra nem teszünk logót és vízjelet sem. Olyan lesz, mintha Te készítetted volna.',
          image: markRaw(PhHeart)
        },
        {
          name: 'Dinamikus átmenetek a felvételek között',
          desc: 'Minden videóból kihozzuk a lehető legtöbbet. Javítjuk a kéz által okozott képremegést, hiszen senki sem született gimbalnak. Szerkesztőink néha kész csodákat művelnek a felvételekkel.',
          image: markRaw(PhSwap)
        },
        {
          name: 'Szín korrekciók',
          desc: 'Az elkészült videóra nem teszünk logót és vízjelet sem. Olyan lesz, mintha Te készítetted volna.',
          image: markRaw(PhEyedropper)
        },
        {
          name: 'Bármilyen kamerával készíthetsz felvételeket',
          desc: 'Ha több kamerát is használtál, legyen az GoPro, DSLR, Telefon, drón, nem probléma. Keverd össze a fájlokat és töltsd fel! A különleges emléked minden pillanata fontos.',
          image: markRaw(PhVideoCamera)
        }
      ]
    }
  }
}
</script>

<style lang="scss">
</style>
