<template>
  <div class="max-w-7xl mx-auto mb-16">
    <h2 class="text-center text-3xl font-bold mb-6">Az időd drága</h2>
    <p class="text-center mb-10">Dőlj hátra és lazíts, miközben felejthetetlen videót készítünk Neked!</p>

    <div class="grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-6 text-center">
      <div v-for="(item, i) in step" :key="i" class="space-y-4 bg-white rounded-lg p-6 shadow-xl">
        <div class="flex justify-center">
          <component :is="item.image" class="text-indigo-600"/>
        </div>
        <div class="text-lg font-bold">
          <span>{{ i+1 }}. </span>
          <span>{{ item.name }}</span>
        </div>
        <div class="">
          {{ item.desc }}
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {markRaw} from "vue"
import { PhMonitorPlay, PhCloudArrowUp, PhPen, PhCircleWavyCheck, PhHorse, PhHeart, PhCube } from "phosphor-vue";

export default {
  provide: {
   size: 48,
   weight: "duotone",
   mirrored: false
  },
  data () {
    return {
      step: [
        {
          name: 'Rendeld meg!',
          desc: 'Válaszd ki a szerkesztés stílusát, hosszát és a feltöltendő felvételek méretét.',
          image: markRaw(PhCircleWavyCheck)
        },
        {
          name: 'Tedd Egyedivé!',
          desc: 'Oszd meg velünk az ötleteidet, hogy a személyes videód még tökéletesebb legyen számodra.',
          image: markRaw(PhPen)
        },
        {
          name: 'Töltsd fel!',
          desc: 'Töltsd fel nekünk a kamerával vagy telefonnal készített emléket, rendezvényt, vagy egy kalandot!',
          image: markRaw(PhCloudArrowUp)
        },
        {
          name: 'Ünnepelj!',
          desc: '7 napon belül küldjük a videót. Oszd meg a csodálatot bárkivel, bármelyik közösségi oldalon.',
          image: markRaw(PhMonitorPlay)
        },
      ]
    }
  }

}
</script>
