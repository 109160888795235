<template>
  <div v-bind:id="player"></div>
</template>

<script>
import Player from '@vimeo/player'
export default {
  props: {
    id: Number
  },
  data () {
    return {
      player: 'player' + this.id,
    }
  },
  mounted () {
    var options = {
      id: this.id,
      responsive: true,
      title: false,
      controls: true,
      quality: '280p',
      muted: false,
      autoplay: true,
      loop: 0,
      autopause: true,
      background: false,
      allow: 'autoplay'
    }
    const player = new Player(this.player, options)
  }
}
</script>

<style lang="scss">
</style>
