<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <nav class="relative z-60 max-w-7xl mx-auto">
    <div class="flex justify-between items-center">
      <div class="flex items-center">
        <div class="mr-4">
          <router-link to="/">
            <img class="w-auto sm:h-10 md:h-12 xl:h-auto" src="@/assets/memofox-logo.svg" alt="">
          </router-link>
        </div>
      </div>
      <div class="space-x-4 flex items-center">
        <router-link v-for="(item, i) in navigation" :key="i" class="font-medium p-3 text-base text-indigo-900 hover:text-indigo-600" :to="{ name: item.to, params: {} }">
          {{ item.name }}
        </router-link>
        <div class="inline-flex">

          <router-link v-if='!loggedIn' class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-lg focus:outline-none border-2 border-indigo-600 text-indigo-600 hover:border-indigo-800 hover:text-indigo-800" :to="{ name: 'Login', params: {} }">
            Bejelentkezés
          </router-link>
          <div class="relative" v-if='loggedIn'>

            <Menu>
              <MenuButton>
                <div class="inline-flex items-center space-x-2 ustify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-lg focus:outline-none border-2 border-indigo-600 text-indigo-600 hover:border-indigo-800 hover:text-indigo-800">
                  <ph-user :size="20" weight="bold" />
                  <span>Profil</span>
                </div>
              </MenuButton>
              <MenuItems class="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div class="px-1 py-1">
                  <MenuItem v-slot="{ active }">
                    <router-link class="bg-violet-500 group flex rounded-md items-center w-full px-2 py-2 text-sm hover:text-white hover:bg-indigo-600 space-x-2" :to="{ name: 'Dashboard', params: {} }">
                      <ph-magic-wand :size="20" weight="regular" />
                      <span></span>
                      Irányítópult
                    </router-link>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <router-link class="bg-violet-500 group flex rounded-md items-center w-full px-2 py-2 text-sm hover:text-white hover:bg-indigo-600 space-x-2" :to="{ name: '', params: {} }">
                      <ph-user-circle :size="20" weight="regular" />
                      <span></span>
                      Profil beállításai
                    </router-link>
                  </MenuItem>
                </div>
                <div class="px-1 py-1">
                  <MenuItem v-slot="{ active }">
                    <router-link @click='$parent.logout' class="bg-violet-500 group flex rounded-md items-center w-full px-2 py-2 text-sm hover:text-white hover:bg-indigo-600 space-x-2" :to="{ name: '', params: {} }">
                      <ph-sign-out :size="20" weight="regular" />
                      <span></span>
                      Kijelentkezés
                    </router-link>
                  </MenuItem>
                </div>
              </MenuItems>
            </Menu>

          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { Popover, PopoverButton, PopoverGroup, PopoverPanel } from '@headlessui/vue'
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue"

export default {
  props: {
    loggedIn: Boolean
  },
  components: {
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
  },
  data() {
    return {
      navigation: [
        {
          name: 'Szerezz egy videót',
          desc: '',
          to: 'Order'
        },
        {
          name: 'Referenciák',
          desc: '',
          to: 'Order'
        },
        {
          name: 'Kapcsolat',
          desc: '',
          to: 'Order'
        },
      ]
    }
  },
  created() {

  }

}
</script>
