<template lang="html">
  <footer class="relative w-full px-16 text-center overflow-hidden">

      <div class="max-w-7xl mx-auto text-left py-16 space-y-16">

        <div class="grid grid-cols-4 gap-4">
          <div class="col-start-1 col-end-1 space-y-6">
            <h4 class="text-md uppercase font-bold text-gray-600">Közösségi linkek</h4>
            <div class="space-y-3 text-indigo-900">
              <div>
                <a class="inline-flex items-center space-x-1 hover:text-indigo-600" href="https://www.instagram.com/memofox.hu/" target="_blank">
                  <span><ph-instagram-logo :size="24" /></span>
                  <span>Instagram</span>
                </a>
              </div>
              <div>
                <a class="inline-flex items-center space-x-1 hover:text-indigo-600" href="#">
                  <span><ph-facebook-logo :size="24" /></span>
                  <span>Facebook</span>
                </a>
              </div>
              <div>
                <a class="inline-flex items-center space-x-1 hover:text-indigo-600" href="#">
                  <span><ph-youtube-logo :size="24" /></span>
                  <span>Youtube</span>
                </a>
              </div>
            </div>
          </div>
          <div class="col-start-2 col-end-2 space-y-6">
            <h4 class="text-md uppercase font-bold text-gray-600">Segítség</h4>
            <div class="space-y-3 text-indigo-900 text-left">
              <div>
                <a class="inline-flex items-center space-x-1 hover:text-indigo-600" href="#">
                  <span><ph-caret-right :size="16" /></span>
                  <span>Gyakori kérdések</span>
                </a>
              </div>
              <div>
                <a class="inline-flex items-center space-x-1 hover:text-indigo-600" href="#">
                  <span><ph-caret-right :size="16" /></span>
                  <span>Kapcsolat</span>
                </a>
              </div>
              <div>
                <a class="inline-flex items-center space-x-1 hover:text-indigo-600" href="#">
                  <span><ph-caret-right :size="16" /></span>
                  <span>Videó szerkesztés státusza</span>
                </a>
              </div>
            </div>
          </div>
          <div class="col-start-3 col-end-3 space-y-6">
            <h4 class="text-md uppercase font-bold text-gray-600">Hogyan lehetünk jobbak?</h4>
            <div class="space-y-3 text-indigo-900 text-left">
              <div>
                <a class="inline-flex items-center space-x-1 hover:text-indigo-600" href="#">
                  <span><ph-caret-right :size="16" /></span>
                  <span>Észrevételek megosztása</span>
                </a>
              </div>
              <div>
                <a class="inline-flex items-center space-x-1 hover:text-indigo-600" href="#">
                  <span><ph-caret-right :size="16" /></span>
                  <span>Hibát találtam az oldalon</span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-1 gap-0">
          <div class="col-start-1 col-end-1 space-y-3 space-x-3">
            <router-link class="text-indigo-900 hover:text-indigo-600 text-sm" :to="{ name: '', params: {} }">Adatkezelési nyilatkozat</router-link> <span class="text-sm text-gray-600">© 2021 MemoFox Inc. All rights reserved.</span>
          </div>
        </div>

      </div>

      <div class="absolute w-full h-full top-0 left-0 right-0 background flex items-center self-center">
        <img class="lg:min-w-full max-w-none min-h-full" src="@/assets/svg/header_hero_background.svg" alt="">
      </div>

  </footer>
</template>

<script type="text/javascript">

</script>
