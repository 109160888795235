<template>
  <div v-if="siteLoader" class="fixed flex items-center justify-center self-center w-screen h-screen top-0 left-0 right-0 z-900 bg-white">
    <div style="border-top-color:transparent" class="w-12 h-12 border-8 border-gray-400 border-solid rounded-full animate-spin"></div>
  </div>
  <div v-if="!siteLoader" class="content font-poppins w-full h-full text-gray-900">
    <section class="w-full px-16 py-16">
      <Nav :loggedIn='loggedIn' />
    </section>

    <router-view />

  </div>
  <Footer class="font-poppins" />
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'

import Nav from '@/views/components/nav'
import Footer from '@/views/components/footer'

export default {
  components: {
    Nav,
    Footer
  },
  data() {
    return {
      siteLoader: false,
      loggedIn: false,
      newOrder: false,
    }
  },
  created() {
    firebase.auth().onAuthStateChanged(user => {
      //this.$parent.loggedIn = !!user
      if (user) {
        this.loggedIn = true
      } else {
        this.loggedIn = false
      }
    })
  },
  methods: {
    async logout () {
      this.siteLoader = true
      try {
        const data = await firebase.auth().signOut()
        document.location.href="/"
      } catch (err) {
        console.log(err)
      }
    },
  }
}

</script>
