import { createStore } from 'vuex'

// https://www.youtube.com/watch?v=dkDoAt26evw&list=PLB4AdipoHpxYPjGo0n2m6tmCLud_iSEbv&index=63

const store = createStore({
  state: {
    cart: null
  },
  getters: {},
  mutations: {
    addToCart(state, item) {
      //webshop function
      //let found = state.cart.find(product => product.productId == item.productId)
      //if (found) {
      //  found.productQuantity++
      //} else {
      //  state.cart.push(item)
      //}

      state.cart = item

      this.commit('saveData')

    },

    saveData(state) {
      window.localStorage.setItem('cart', state.cart)
    }

  },
  actions: {}
})

export default store
